<template>
  <section id="login" class="route">
    <loader v-if="loading" :loading-message="`Logging in...`" />

    <form class="flex--column" @submit.prevent="login">
      <fieldset>
        <legend>
          <i class="fas fa-lock accent--text"></i>
          Welcome
        </legend>
        <label for="username" class="column align-start">
          <span :class="requiredField('username')">Username</span>
          <input
            @input="clearErrors"
            aria-label="Username"
            class="wide"
            name="username"
            placeholder="Enter username"
            type="text"
            v-model="form.username"
          />
        </label>

        <label for="password" class="column align-start">
          <span :class="requiredField('password')">Password</span>
          <input
            @input="clearErrors"
            class="wide"
            name="password"
            placeholder="Enter password"
            type="password"
            v-model="form.password"
          />
        </label>

        <!-- Controls -->
        <div class="justify">
          <button type="submit" v-text="'Log in'" />
          <router-link :to="{ name: 'ResetPassword' }">
            I forgot my password
          </router-link>
        </div>
      </fieldset>

      <p class="error--text" v-if="error" v-text="error" />

      <div class="content content--centered">
        New user?
        <hr class="divider divider--vertical" />
        <router-link :to="registerUserRoute">Create an account</router-link>
      </div>
    </form>
  </section>
</template>

<style lang="scss">
#login {
  @include flex;
  padding-top: 0;
  place-content: center;
}

#login form {
  @include flex;
  background-color: white;
  max-width: 24rem;
  width: 100%;

  > * {
    width: 100%;
  }

  fieldset {
    @include elevated;
    padding: $sm;
  }
}
</style>

<script>
import Loader from "../components/Loader.vue";
import FormsMixin from "../components/mixins/forms.mixin";
import PermissionsMixin from "../components/mixins/permissions.mixin";
import session from "../session";

export default {
  name: "Login",

  components: { Loader },

  mixins: [FormsMixin, PermissionsMixin],

  data: () => ({
    requiredFields: ["username", "password"],
    username: "",
    password: "",
    loading: false
  }),

  computed: {
    registerUserRoute() {
      const { next } = this.$route.query;
      const route = { name: "RegisterUser" };
      if (next) route.query = { next };
      return route;
    }
  },

  methods: {
    async login() {
      this.clearErrors();
      this.startLoading("Logging in ...");

      try {
        const { username, password } = this.form;
        await session.login(username, password);

        // Navigate to next or home
        const ignored = ["/", "/login"];
        const { next } = this.$route.query;
        const dest = !next || ignored.includes(next) ? { name: "Home" } : next;
        this.stopLoading();
        this.$router.push(dest);
      } catch (error) {
        this.onFormError(error);
      }
    }
  }
};
</script>
